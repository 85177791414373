import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute } from '@angular/router';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-my-bets',
  templateUrl: './my-bets.component.html',
  styleUrls: ['./my-bets.component.scss']
})

export class MyBetsComponent implements OnInit {
  matchoddMarket:any=[];
  bookmakerMarket:any=[];
  toWinTossMarket:any=[];
  sessionMarket:any=[];
  userDetails: any;
  allBetData:any;
  selectedMarket:any;
  param:any;
  matchData:any;
  betData:any;
  userData:any;
  match_id: any;
  market_id: any;
  typeId: any;
  sport_name:string;
  series_name:string;
  match_name:string;
  market_name:any;
  
  constructor(private  activatedroute:ActivatedRoute,private  router:Router,private socket: Socket) { 
        this.activatedroute.params.subscribe((params) => {
        this.match_id = params.matchId;
        this.market_id = params.marketId;
        this.typeId = params.type;
      });
     
    }

  ngOnInit(): void {
    this.getMyBets();
  }

  async getDetials()
  {
    try {
      const data=await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }
  
  }

  async getMyBets() {
    this.userDetails=await this.getDetials();
    const getBet = {
      user: {
         _id: this.userDetails._id,
         key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      filter: {
        username: this.userDetails.details.username,
        deleted: false,
        result: 'ACTIVE',
      },
      sort: {placedTime: -1},
    };
    
    this.socket.emit('get-bets', getBet);

    this.socket.on('get-bets-success', (function(data:any){ 
       //console.warn('allBet',data);
      this.allBetData=data;
      this.getMarket();
     }).bind(this));
  }

  getMarket() {
    const market = {
      user: {
         _id: this.userDetails._id,
         key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      filter: {
        managers: this.userDetails.details.manager,
        deleted: false,
        'marketBook.status': {
          $ne: 'CLOSED',
        },
      },
      sort: {placedTime: -1}
    };

    this.socket.emit('get-multi-market', market);

    this.socket.on('get-multimarkets-success', (function(markets:any){     
      this.markets = markets;
      this.matchoddMarket=[];
      this.bookmakerMarket=[];
      this.toWinTossMarket=[];
      this.sessionMarket=[];
      if(!markets)return;
       for(let i=0;i<this.markets.length;i++)
       {
        if(markets[i].marketType==='SESSION')
        {
        this.sessionMarket.push(markets[i]);
        }
        else if(markets[i].marketType==='Special')
        {
        this.bookmakerMarket.push(markets[i]);
        }
        else if(markets[i].marketName === 'TO Win Toss')
        {
         this.toWinTossMarket.push(markets[i]);
        }
        else{
         this.matchoddMarket.push(markets[i]);
        }
      }
     }).bind(this));
  }  

  searchRunner(runners: any[], id: string): any {
     
    if(!runners) return null;
    for (var key in runners) {
      if(runners[key].selectionId == id) 
      return runners[key].runnerName;
    }
    
  }

  calProLoss(a:any,data:any,index:number,matchOddsData:any)
  {
     
    if(a&&this.allBetData)
    {
      // bookmaker/matchodds seprate array whole market (Matchodds/Special)
      let test = this.allBetData.filter(item => {
        return item.marketId == matchOddsData.marketId;
      });

     let betsValue = test.filter(item => {
      if (a.selectionId != undefined) {
        return item.runnerId == a.selectionId;
      } else {
        return item.runnerId == data.runners[index].selectionId;
      }
    });

     let laystaketotal = test.filter(item => {
      if (a.selectionId != undefined) {
        return item.runnerId != a.selectionId;
      } else {
        return item.runnerId != data.runners[index].selectionId;
      }
    });
 
  let backData = betsValue.filter(item => {
   return item.type == 'Back';
  });

  let layData = betsValue.filter(item => {
  return item.type == 'Lay';
  });

  let oppBack = laystaketotal.filter(item => {
  return item.type == 'Back';
  });

  let totalOppBack = 0;
  oppBack.map(b => {
  totalOppBack = totalOppBack + b.stake;
  });

  let oppLay = laystaketotal.filter(item => {
   return item.type == 'Lay';
  });

  let totalOppLay = 0;
  oppLay.map(b => {
  totalOppLay = totalOppLay + b.stake;
  });


  let backvalue = 0;
  backData.map(b => {
  let back = b.stake * (b.rate - 1);
  backvalue = backvalue + back;
  });

  let layvalue = 0;
  layData.map(b => {
  let lay = b.stake * (b.rate - 1);
  layvalue = layvalue + lay;
  });

  let backtotal = backvalue - totalOppBack;
  let laytotal = totalOppLay - layvalue;

  let markettotal;
 
  markettotal = backtotal + laytotal;

  return (markettotal);
    }

  }
  

  matchDetail(eventId, eventTypeId)
  {
    if(eventTypeId=='b9'){
      this.router.navigate(['ballbyball-detail']);
    }
    else if(eventTypeId=='v9'){
      this.router.navigate(['virtual-detail',eventId]);
    }
    else{
    this.router.navigate(['match-detail',eventId]);
    }
  }

  ngOnDestroy() 
  {
    this.socket.removeListener('get-bets-success');
    this.socket.removeListener('get-multimarkets-success');
  }

}
